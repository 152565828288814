<template lang="pug">
.today-block.flex.align-stretch.justify-stretch(:style="style")
  .card.flex-1.flex.column.gap-10(v-if="type === 'appointment'",
    @click="showAppointment"
    :style="cardStyle")
    .name.flex.align-center
      .clickable.text-clickable.bold(@click="showCase(data.patient.id, $event)")
        span {{ data.patient?.family_name }}
        span {{ data.patient?.given_name }}
      template(v-if="data?.appointment_type !== 'parent'")
        template(v-for="(p, idx) in data?.partners")
          span(:key="`dot-${idx}`") 、
          .bold.link-text.clickable(
            :key="p.id"
            @click="showCase(p.id, $event)"
            ) {{ p.family_name }}{{ p.given_name }}
      template(v-else)
        template(v-for="(p, idx) in data?.partners")
          .name.clickable.text-clickable.ml-4(
            :key="p.id"
            @click="showCase(p.id, $event)"
            )
            span (
            template(v-if="data?.subtype === 'statutoryagents'")
              span 法代
              span :
            template(v-else-if="data?.subtype === 'children'")
              span 子女
              span :
            span {{ p.family_name }}{{ p.given_name }}
            span )
    .data.flex.column
      .data-row
        template(v-if="data.location_type === 'inhouse'")
          i.el-icon-office-building
          .value.flex.align-center
            span 實體 /
            span &nbsp;{{ data.room.branch.name }}
            span &nbsp;{{ data.room.name }}
      .data-row
        i.el-icon-money
        .value NT$ {{ data.price }}
      .data-row(v-if="data.project")
        i.el-icon-collection
        .value {{ data.project_organization }} - {{ data.project }}
      .data-row
        i.el-icon-chat-line-round
        .value {{ data.note || '---' }}
      .data-row(v-if="data.need_noted")
        i.el-icon-warning-outline
        el-checkbox(v-model="data.noted", @click.native="checkNoted", label="需支援報備" border)
  .card.flex-1.gap-10(v-if="type === 'activity'", @click="showActivity(data)")
    .name.flex.align-center.bold
      span {{ data.name }} ({{data.organization}})
    .data.flex.column
      .data-row
        template(v-if="data.location_type === 'inhouse'")
          i.el-icon-office-building
          .value.flex.align-center
            span 實體 /
            span &nbsp;{{ data.room.branch.name }}
            span &nbsp;{{ data.room.name }}
      .data-row
        i.el-icon-money
        .value NT$ {{ data.price }}
      .data-row
        i.el-icon-chat-line-round
        .value {{ data.note || '---' }}
  person-viewer(
    patient-mode
    simple-mode
    pro-edit
    hide-detail
    hide-open-chat
    show-open-record
    ref="personViewer"
    @showDetail="showDetail"
    @showProRecord="showProRecord"
  )
</template>

<script>
import { setAppointmentNoted } from '@/api/reserve';
import PersonViewer from '@/components/drawers/PersonViewer.vue';

export default {
  name: 'TodayBlock',
  components: {
    PersonViewer,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'appointment',
    },
  },
  computed: {
    hour() {
      return (new Date(this.data?.start_at * 1000)).getHours();
    },
    half() {
      return (new Date(this.data?.start_at * 1000)).getMinutes() >= 30;
    },
    yPos() {
      return this.hour * 158 * 2 + (this.half ? 158 : 0);
    },
    mins() {
      return (this.data?.end_at - this.data?.start_at) / 60;
    },
    height() {
      const slots = parseInt(this.mins / 30, 10);
      return slots * 158;
    },
    bgColor() {
      const payment = this.data.payment || this.data.payment_method;
      if (!this.data.project && this.data.location_type === 'virtual'
       && (payment === undefined || payment === 'notpaid')) {
        return '#FEF0F0';
      }
      return 'white';
    },
    style() {
      return {
        top: `${this.yPos}px`,
        height: `${this.height}px`,
      };
    },
    cardStyle() {
      return {
        backgroundColor: this.bgColor,
      };
    },
  },
  methods: {
    showAppointment() {
      this.$emit('showAppointment', this.data);
    },
    showProRecord(id) {
      this.$emit('showProRecord', id);
    },
    showDetail(user) {
      this.$root.$emit('show-user', user);
    },
    showCase(id, e) {
      this.$refs.personViewer.$emit('show', {
        type: 'id',
        data: id,
      });
      e.stopPropagation();
      e.preventDefault();
    },
    showActivity(data) {
      this.$emit('showActivity', data);
    },
    checkNoted(ev) {
      ev.stopPropagation();
      ev.preventDefault();
      this.data.noted = !this.data.noted;
      this.$execWithLoading(async () => {
        await setAppointmentNoted(this.data.id, this.data.noted);
        this.$showSuccess(this.data.noted ? '已設定完成報備' : '已取消完成報備');
      }, (e) => {
        this.$showAxiosException('設定報備狀態失敗', e);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.today-block {
  position: absolute;
  padding: 4px;
  width: 540px;
  .card {
    background: #FFFFFF;
    /* box-shadow */
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
      0px 2px 6px rgba(0, 0, 0, 0.06),
      0px 4px 8px 2px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    padding: 10px 20px;
    .name {
      flex: 0 0 24px;
    }
    .data {
      .data-row {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 0 0 24px;
        i {
          color: $pro-active-color;
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .today-block {
    width: calc(100vw - 84px);
  }
}
</style>
